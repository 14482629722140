import { useTranslation } from "react-i18next";

import { Container, OvalImage, CircularImage } from "components";
import { SendUsAMessageForm } from "layouts/forms";

import "./index.css";

const SendUsAMessage = () => {
  const { t: translation } = useTranslation("layouts", {
    keyPrefix: "sendUsAMessage",
  });

  return (
    <div className="sendUsAMessage" id="sendUsAMessage">
      <Container>
        <div className="sendUsAMessage__columns">
          <div className="sendUsAMessage__column sendUsAMessage__firstColumn">
            <div className="sendUsAMessage__image1">
              <OvalImage
                image="/media/family-shots/bottles-wide-4.png"
                style={{ width: 285, height: 432, borderRadius: 187 }}
              />
            </div>

            <div className="sendUsAMessage__image2">
              <CircularImage
                image="/media/family-shots/glasses-wide-1.jpg"
                size={292}
              />
            </div>
          </div>

          <div className="sendUsAMessage__column">
            <h2 className="sendUsAMessage__title section-title section-title-divider section-title-divider-start">
              {translation("title")}
            </h2>

            <p className="sendUsAMessage__description paragraph">
              {translation("description")}
            </p>

            <SendUsAMessageForm />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SendUsAMessage;
