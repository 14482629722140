import { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { testInputValidation } from "utils/helpers";

import { ValidationMessage } from "components";

import "./index.css";

const INPUT_CHANGE = "INPUT_CHANGE";
const INPUT_VALIDATION = "INPUT_VALIDATION";
const INPUT_RESET = "INPUT_RESET";

const inputInitialState = {
  value: "",
  isValid: true,
  errorMessage: "",
};

const inputReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        value: action.payload.value,
      };
    case INPUT_VALIDATION:
      const inputValidation = testInputValidation(
        action.payload.input,
        state.value
      );

      return {
        ...state,
        isValid: inputValidation.isValid,
        errorMessage: inputValidation.errorMessage,
      };
    case INPUT_RESET:
      return inputInitialState;

    default:
      return state;
  }
};

const Input = ({
  formInput,
  onFormInputChangeHandler,
  isFormValid = false,
  isFormSubmitted = false,
  ...rest
}) => {
  const { t: translation } = useTranslation("common", {
    keyPrefix: "formValidation",
  });
  const [inputState, dispatchInputState] = useReducer(
    inputReducer,
    inputInitialState
  );

  const onChangeHandler = (event) => {
    const { value } = event.target;

    dispatchInputState({
      type: INPUT_CHANGE,
      payload: {
        value: value,
      },
    });
  };

  const onBlurHandler = (event) => {
    const { name, value } = event.target;

    dispatchInputState({
      type: INPUT_VALIDATION,
      payload: {
        input: formInput,
      },
    });

    onFormInputChangeHandler(name, value);
  };

  useEffect(() => {
    if (!isFormValid) {
      dispatchInputState({
        type: INPUT_VALIDATION,
        payload: {
          input: formInput,
        },
      });
    }
  }, [isFormValid, formInput]);

  useEffect(() => {
    if (isFormSubmitted) {
      dispatchInputState({ type: INPUT_RESET });
    }
  }, [isFormSubmitted]);

  let Component = "input";

  if (formInput?.type === "textarea") {
    Component = "textarea";
  }

  return (
    <div className="input">
      <Component
        className="input__component"
        type={formInput?.type}
        name={formInput?.inputIdentifier}
        value={inputState.value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        {...rest}
      />

      {!inputState?.isValid && inputState?.errorMessage ? (
        <ValidationMessage message={translation(inputState.errorMessage)} />
      ) : null}
    </div>
  );
};

export default Input;
