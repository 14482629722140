import { useEffect } from "react";

import "./index.css";

const bodyTag = document.querySelector("body");

const Modal = ({ isModalOpen = false, closeModal, children }) => {
  useEffect(() => {
    isModalOpen
      ? (bodyTag.style.overflow = "hidden")
      : (bodyTag.style.overflow = "auto");
  }, [isModalOpen]);

  return isModalOpen ? (
    <div className="modal">
      <button className="modal__close" onClick={closeModal}>
        X
      </button>

      <div className="modal__content">{children}</div>
    </div>
  ) : null;
};

export default Modal;
