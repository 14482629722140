import { useNavigate } from "react-router-dom";

import { LINKS } from "utils";

import FooterMenu from "./FooterMenu";
import { Container, Logo } from "components";

import "./index.css";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToHomepage = () => {
    navigate(LINKS.home);
  };

  return (
    <div className="footer">
      <Container>
        <div className="footer__columns">
          <div className="footer__column">
            <div
              className="footer__logo"
              onClick={navigateToHomepage}
              tabIndex="0"
            >
              <Logo width={137.46} />
            </div>
          </div>

          <div className="footer__column">
            <FooterMenu />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
