import texturedBackground from "assets/media/textured-background.jpg";

import { Header, Footer, Copyright, FirstPurchasePopup } from "layouts";

import "./index.css";

const Main = ({ children }) => {
  return (
    <div
      className="mainLayout"
      style={{ backgroundImage: `url(${texturedBackground})` }}
    >
      <Header />

      <FirstPurchasePopup />

      {children}

      <Footer />

      <Copyright />
    </div>
  );
};

export default Main;
