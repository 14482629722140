import { useProducts } from "contexts/ProductsContext";

import { ProductCard } from "layouts";

import "./index.css";

const ProductRecommendation = ({ excludeProductId }) => {
  const { products } = useProducts();

  return (
    <div className="recommendation">
      <p className="recommendation__title">You may also like</p>

      <div className="recommendation__columns">
        {products
          .sort((a, b) => a.order - b.order)
          .map((element) => {
            if (element.id === excludeProductId) return null;

            return (
              <div className="recommendation__column" key={element.id}>
                <ProductCard product={element} actionButtonsHidden={true} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductRecommendation;
