import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./index.css";

const ThumbsGallery = ({ slides = [] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="thumbsGallery">
      <Swiper
        className="thumbsGallery__swiper"
        spaceBetween={16}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        grabCursor={true}
      >
        {slides.map((element, index) => (
          <SwiperSlide key={index}>
            <img
              className="thumbsGallery__swiperImage"
              src={element}
              alt={`slide ${index + 1}`}
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className="thumbsGallery__swiperThumbs"
        onSwiper={setThumbsSwiper}
        spaceBetween={16}
        slidesPerView={3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {slides.map((element, index) => (
          <SwiperSlide key={index}>
            <img
              className="thumbsGallery__swiperImage"
              src={element}
              alt={`slide ${index + 1}`}
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ThumbsGallery;
