import Product from "models/product";

const PRODUCTS = [
  new Product(
    "bloody-mary",
    "Bloody Mary",
    "Drowsy mornings are calling for a spicy kick to the senses and quality time with Mary.",
    24.99,
    3,
    ["250 ml", "500 ml", "750 ml"],
    "/media/bloody-mary/bloody-mary-tall-1.jpg",
    [
      "/media/bloody-mary/bloody-mary-tall-1.jpg",
      "/media/bloody-mary/bloody-mary-tall-2.jpg",
    ],
    ["1 L – (up to 8 drinks)", "500 ml (up to 4 drinks)"],
    [
      "Tomato juice",
      "Worcestershire sauce",
      "Perfected spice mix",
      "Less than 70 kcal/serving",
      "Lasts up to 7 days refrigerated",
    ],
    {
      ratio: "¾ Qure Mix + ¼ Spirit",
      steps: [
        {
          title: "Add 140ml of the mixer",
          icon: "/media/bloody-mary/icon-1.png",
        },
        {
          title: "Add 45ml vodka or gin",
          icon: "/media/bloody-mary/icon-2.png",
        },
        {
          title: "Add ice\n& stir well",
          icon: "/media/bloody-mary/icon-3.png",
        },
        {
          title: "Garnish with celery & olives",
          icon: "/media/bloody-mary/icon-4.png",
        },
      ],
    },
    1
  ),
  new Product(
    "basil-smash",
    "Basil Smash",
    "Ideal for daydreams of jumping into cool lakes, the scent of freshly crushed basil filling the air.",
    24.99,
    3,
    ["250 ml", "500 ml", "750 ml"],
    "/media/basil-smash/basil-smash-tall-1.jpg",
    [
      "/media/basil-smash/basil-smash-tall-1.jpg",
      "/media/basil-smash/basil-smash-tall-2.jpg",
      "/media/basil-smash/basil-smash-tall-3.jpg",
    ],
    ["1 L – (up to 8 drinks)", "500 ml (up to 4 drinks)"],
    [
      "Basil leaves",
      "Lemon juice",
      "Lime juice",
      "Less than 125 kcal/serving",
      "Lasts up to 7 days refrigerated",
    ],
    {
      ratio: "2/3 Qure Mix + 1/3 Spirit",
      steps: [
        {
          title: "Add 125ml of the mixer",
          icon: "/media/basil-smash/icon-1.png",
        },
        {
          title: "Add 60ml of your favorite gin",
          icon: "/media/basil-smash/icon-2.png",
        },
        {
          title: "Add Ice\n& Stir well",
          icon: "/media/basil-smash/icon-3.png",
        },
        {
          title: "Garnish with basil leaves",
          icon: "/media/basil-smash/icon-4.png",
        },
      ],
    },
    2
  ),
  new Product(
    "dubai-mule",
    "Dubai Mule",
    "When the city says hello to summer, it also offers apologies with fresh ginger swirled in cool copper.",
    24.99,
    3,
    ["250 ml", "500 ml", "750 ml"],
    "/media/dubai-mule/dubai-mule-tall-1.jpg",
    [
      "/media/dubai-mule/dubai-mule-tall-1.jpg",
      "/media/dubai-mule/dubai-mule-tall-2.jpg",
      "/media/dubai-mule/dubai-mule-tall-3.jpg",
    ],
    ["1 L – (up to 8 drinks)", "500 ml (up to 4 drinks)"],
    [
      "Ginger",
      "Pineapple",
      "Lemon juice",
      "Lime juice",
      "Less than 115 kcal/serving",
      "Lasts up to 7 days refrigerated",
    ],
    {
      ratio: "2/3 Qure Mix + 1/3 Spirit",
      steps: [
        {
          title: "Add 125ml of the mixer",
          icon: "/media/dubai-mule/icon-1.png",
        },
        {
          title: "Add 60ml of your favorite white spirit",
          icon: "/media/dubai-mule/icon-2.png",
        },
        {
          title: "Top with a dash of soda, add ice & stir well",
          icon: "/media/dubai-mule/icon-3.png",
        },
        {
          title: "Garnish with dried ginger",
          icon: "/media/dubai-mule/icon-4.png",
        },
      ],
    },
    3
  ),
  new Product(
    "espresso-martini",
    "Espresso Martini",
    "For nights that should never end; silky smooth memories in the making that taste like your favorite freshly brewed coffee.",
    24.99,
    3,
    ["250 ml", "500 ml", "750 ml"],
    "/media/espresso-martini/espresso-martini-tall-1.jpg",
    [
      "/media/espresso-martini/espresso-martini-tall-1.jpg",
      "/media/espresso-martini/espresso-martini-tall-2.jpg",
      "/media/espresso-martini/espresso-martini-tall-3.jpg",
    ],
    ["1 L – (up to 8 drinks)", "500 ml (up to 4 drinks)"],
    [
      "Fresh coffee",
      "Cocoa nibs",
      "Less than 165 kcal/serving",
      "Lasts up to 7 days refrigerated",
    ],
    {
      ratio: "2/3 Qure Mix + 1/3 Spirit",
      steps: [
        {
          title: "Add 125ml of the mixer",
          icon: "/media/espresso-martini/icon-1.png",
        },
        {
          title: "Add 60ml vodka",
          icon: "/media/espresso-martini/icon-2.png",
        },
        {
          title: "Add ice\n& stir well",
          icon: "/media/espresso-martini/icon-3.svg",
        },
        {
          title: "Garnish with coffee beans",
          icon: "/media/espresso-martini/icon-4.png",
        },
      ],
    },
    4
  ),
  new Product(
    "sangria-punch",
    "Sangria Punch",
    "Paired perfectly with sunshine, transporting you to afternoons at orchards filled with berries.",
    24.99,
    3,
    ["250 ml", "500 ml", "750 ml"],
    "/media/sangria-punch/sangria-punch-tall-1.jpg",
    [
      "/media/sangria-punch/sangria-punch-tall-1.jpg",
      "/media/sangria-punch/sangria-punch-tall-2.jpg",
      "/media/sangria-punch/sangria-punch-tall-3.jpg",
    ],
    ["1 L – (up to 8 drinks)", "500 ml (up to 4 drinks)"],
    [
      "Goji berries",
      "Cranberries",
      "Apples",
      "Less than 110 kcal/serving",
      "Lasts up to 7 days refrigerated",
    ],
    {
      ratio: "60% Qure Mix + 40% Spirit",
      steps: [
        {
          title: "Add 100ml of the mixer",
          icon: "/media/sangria-punch/icon-1.png",
        },
        {
          title: "Add 60ml of your favorite wine",
          icon: "/media/sangria-punch/icon-2.png",
        },
        {
          title: "Add ice\n& stir well",
          icon: "/media/sangria-punch/icon-3.png",
        },
        {
          title: "Garnish with dried fruits",
          icon: "/media/sangria-punch/icon-4.svg",
        },
      ],
    },
    5
  ),
];

export default PRODUCTS;
