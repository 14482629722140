import "./index.css";

const Burger = ({ isToggled = false, setIsToggled }) => {
  const onToggleHandler = () => {
    setIsToggled(!isToggled);
  };

  return (
    <button
      className={`burger ${isToggled ? "active" : ""}`}
      onClick={onToggleHandler}
    >
      <span className="burger__slice"></span>

      <span className="burger__slice"></span>

      <span className="burger__slice"></span>
    </button>
  );
};

export default Burger;
