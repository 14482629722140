import "./index.css";

const Fact = ({ image, title, description }) => {
  return (
    <div className="fact">
      <img className="fact__img" src={image} alt={title} />

      <p className="fact__title">{title}</p>

      <p className="fact__description">{description}</p>
    </div>
  );
};

export default Fact;
