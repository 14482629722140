import { useState } from "react";

import { Modal, Logo } from "components";
import { FirstPurchaseForm } from "layouts/forms";

import "./index.css";

const FirstPurchasePopup = () => {
  const [isModalOpen, setIsModalOpen] = useState(
    localStorage.getItem("firstPurchase") ? false : true
  );
  const [isChecked, setIsChecked] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const toggleChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const closeModal = () => {
    if (isChecked) {
      localStorage.setItem(
        "firstPurchase",
        "Walid Hawari will not show you the modal again by using the localStorage web API"
      );
    }

    setIsModalOpen(false);
  };

  return (
    <Modal isModalOpen={isModalOpen} closeModal={closeModal}>
      {formMessage === "success" ? (
        <div className="firstPurchasePopup__success">
          <h1>Thank you</h1>

          <p>Check your email to get your discount.</p>

          <Logo />
        </div>
      ) : (
        <div className="firstPurchasePopup firstPurchasePopup__columns">
          <div
            className="firstPurchasePopup__column firstPurchasePopup__backgroundImg"
            style={{
              backgroundImage: "url(/media/family-shots/bottles-tall-1.png)",
            }}
          ></div>

          <div className="firstPurchasePopup__column firstPurchasePopup__content">
            <h1>
              Enjoy <br /> <span style={{ fontWeight: "bold" }}>15% OFF</span>{" "}
              <br /> on your <br /> first order!
            </h1>

            <FirstPurchaseForm
              formMessage={formMessage}
              setFormMessage={setFormMessage}
            />

            <div className="firstPurchasePopup__labelWrap">
              <label className="firstPurchasePopup__label">
                <input
                  id="hideModalCheckbox"
                  type="checkbox"
                  checked={isChecked}
                  onChange={toggleChecked}
                />

                <span className="firstPurchasePopup__checkMark"></span>
              </label>

              <label htmlFor="hideModalCheckbox">Don't show again</label>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FirstPurchasePopup;
