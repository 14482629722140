import { useTranslation } from "react-i18next";

import drinks from "assets/media/drinks.png";
import fresh from "assets/media/fresh.svg";
import premium from "assets/media/premium.svg";
import convenient from "assets/media/convenient.svg";
import delicious from "assets/media/delicious.svg";

import { Container, Fact } from "components";

import "./index.css";

const MeantToBeShared = () => {
  const { t: translation } = useTranslation("layouts", {
    keyPrefix: "meantToBeShared",
  });

  return (
    <div className="meantToBeShared" id="meantToBeShared">
      <Container>
        <h2 className="meantToBeShared__title section-title section-title-divider">
          {translation("title")}
        </h2>

        <img
          className="meantToBeShared__drinksImage"
          src={drinks}
          alt="drink"
          loading="lazy"
        />

        <div className="meantToBeShared__columns">
          <div className="meantToBeShared__column">
            <Fact
              image={fresh}
              title={translation("fact1.title")}
              description={translation("fact1.description")}
            />
          </div>

          <div className="meantToBeShared__column">
            <Fact
              image={premium}
              title={translation("fact2.title")}
              description={translation("fact2.description")}
            />
          </div>

          <div className="meantToBeShared__column">
            <Fact
              image={convenient}
              title={translation("fact3.title")}
              description={translation("fact3.description")}
            />
          </div>

          <div className="meantToBeShared__column">
            <Fact
              image={delicious}
              title={translation("fact4.title")}
              description={translation("fact4.description")}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MeantToBeShared;
