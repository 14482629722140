import { useTranslation } from "react-i18next";

import { HELPERS } from "utils";

import { Slider } from "components";
import { Button } from "components/buttons";

import "./index.css";

const AboveTheFold = () => {
  const { t: translation } = useTranslation("layouts", {
    keyPrefix: "aboveTheFold",
  });

  const scrollToProducts = () => {
    const mocktailsCollection = document.getElementById("mocktailsCollection");

    HELPERS.scrollIntoSection(mocktailsCollection);
  };

  return (
    <div className="aboveTheFold">
      <div className="aboveTheFold__columns">
        <div className="aboveTheFold__column aboveTheFold__content">
          <h1 className="aboveTheFold__title">{translation("title")}</h1>

          <p className="aboveTheFold__subtitle">{translation("subtitle")}</p>

          <Button onClick={scrollToProducts}>
            {translation("actionButton")}
          </Button>
        </div>

        <div className="aboveTheFold__column aboveTheFold__sliderWrap">
          <Slider
            slides={[
              {
                image: "/media/bloody-mary/bloody-mary-tall-1.jpg",
                imageAlt: "Bloody Mary",
              },
              {
                image: "/media/basil-smash/basil-smash-tall-1.jpg",
                imageAlt: "Basil Smash",
              },
              {
                image: "/media/dubai-mule/dubai-mule-tall-1.jpg",
                imageAlt: "Dubai Mule",
              },
              {
                image: "/media/espresso-martini/espresso-martini-tall-1.jpg",
                imageAlt: "Espresso Martini",
              },
              {
                image: "/media/sangria-punch/sangria-punch-tall-1.jpg",
                imageAlt: "Sangria Punch",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AboveTheFold;
