// ----------------------------------------------------------------- //

export const testEmailValidation = (email) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

// ----------------------------------------------------------------- //

export const testInputValidation = (input, inputValue) => {
  let isValid = true;
  let errorMessage = "";

  if (
    input?.type !== "number" &&
    input?.isRequired &&
    inputValue.trim().length === 0
  ) {
    isValid = false;
    errorMessage = "required";
  } else if (input?.type === "number" && input.value < 1) {
    isValid = false;
    errorMessage = "minNumberRequired";
  } else if (input?.type === "email" && !testEmailValidation(inputValue)) {
    isValid = false;
    errorMessage = "invalidEmail";
  }

  return {
    isValid: isValid,
    errorMessage: errorMessage,
  };
};

// ----------------------------------------------------------------- //

export const testFormValidation = (inputs) => {
  let isFormValid = true;

  for (const entry of Object.values(inputs)) {
    let inputValidation = testInputValidation(entry, entry.value);

    if (!inputValidation.isValid) {
      isFormValid = false;

      break;
    }
  }

  return isFormValid;
};

// ----------------------------------------------------------------- //

export const scrollIntoSection = (element) => {
  const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = 175;
  window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
};

// ----------------------------------------------------------------- //

const HELPERS = {
  testEmailValidation: testEmailValidation,
  testInputValidation: testInputValidation,
  testFormValidation: testFormValidation,
  scrollIntoSection: scrollIntoSection,
};

export default HELPERS;
