import "./index.css";

const OvalImage = ({ image, size, ...rest }) => {
  return (
    <div className="ovalImage" {...rest}>
      <img src={image} alt="oval" loading="lazy" />
    </div>
  );
};

export default OvalImage;
