import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import { HELPERS } from "utils";

import "./index.css";

const HeaderMenu = ({ setIsToggled }) => {
  const { t: translation } = useTranslation("common", {
    keyPrefix: "headerMenu",
  });

  return (
    <nav className="headerMenu">
      <HashLink
        className="headerMenu__hyperlink"
        to="/#ourStory"
        smooth
        scroll={HELPERS.scrollIntoSection}
        onClick={() => {
          setIsToggled(false);
        }}
      >
        {translation("ourStory")}
      </HashLink>

      <HashLink
        className="headerMenu__hyperlink"
        to="/#mocktailsCollection"
        smooth
        scroll={HELPERS.scrollIntoSection}
        onClick={() => {
          setIsToggled(false);
        }}
      >
        {translation("products")}
      </HashLink>

      <HashLink
        className="headerMenu__hyperlink"
        to="/#meantToBeShared"
        smooth
        scroll={HELPERS.scrollIntoSection}
        onClick={() => {
          setIsToggled(false);
        }}
      >
        {translation("whyQure")}
      </HashLink>

      <HashLink
        className="headerMenu__hyperlink"
        to="/#sendUsAMessage"
        smooth
        scroll={HELPERS.scrollIntoSection}
        onClick={() => {
          setIsToggled(false);
        }}
      >
        {translation("contactUs")}
      </HashLink>
    </nav>
  );
};

export default HeaderMenu;
