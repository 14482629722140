import { createContext, useContext, useReducer } from "react";

import CartItem from "models/cartItem";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const addToCart = (product, volume, quantity) => {
  return {
    type: ADD_TO_CART,
    payload: {
      product: product,
      volume: volume,
      quantity: quantity,
    },
  };
};

export const removeFromCart = (productId) => {
  return { type: REMOVE_FROM_CART, payload: { productId: productId } };
};

const initialState = {
  items: {},
  totalPrice: 0,
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const addedProduct = action.payload.product;
      const quantity = action.payload.quantity;
      // const volume = action.payload.volume;

      let cartItem;

      // Product already in cart
      if (state.items[addedProduct.id]) {
        cartItem = new CartItem(
          addedProduct.title,
          addedProduct.imageUrl,
          addedProduct.price,
          state.items[addedProduct.id].quantity + addedProduct.quantity,
          state.items[addedProduct.id].totalPrice +
            addedProduct.price * quantity
        );
      } else {
        cartItem = new CartItem(
          addedProduct.title,
          addedProduct.imageUrl,
          addedProduct.price,
          quantity,
          addedProduct.price * quantity
        );
      }

      return {
        ...state,
        items: { ...state.items, [addedProduct.id]: cartItem },
        totalPrice: state.totalPrice + addedProduct.price * quantity,
      };
    case REMOVE_FROM_CART:
      const productId = action.payload.productId;
      const selectedCartItem = state.items[productId];

      let updatedCartItems = { ...state.items };
      delete updatedCartItems[productId];

      return {
        ...state,
        items: updatedCartItems,
        totalPrice: state.totalPrice - selectedCartItem.totalPrice,
      };
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatchCart] = useReducer(cartReducer, initialState);

  const value = {
    cart,
    dispatchCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
