class CartItem {
  constructor(
    productTitle,
    productImageUrl,
    productPrice,
    quantity,
    totalPrice
  ) {
    this.productTitle = productTitle;
    this.productImageUrl = productImageUrl;
    this.productPrice = productPrice;
    this.quantity = quantity;
    this.totalPrice = totalPrice;
  }
}

export default CartItem;
