const Facebook = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.329 19.82">
      <path
        id="Path_51"
        data-name="Path 51"
        d="M8.235,4.85h2.094V1.64c-.279,0-.7-.14-.977-.14H7.4a4.6,4.6,0,0,0-2.652.837A4,4,0,0,0,3.21,4.989a4.748,4.748,0,0,0-.14,1.4V8.758H0v3.629H3.071V21.32H6.839V12.387H9.771c.14-1.256.279-2.373.419-3.629H6.7V6.246A1.425,1.425,0,0,1,8.235,4.85Z"
        transform="translate(0 -1.5)"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </svg>
  );
};

export default Facebook;
