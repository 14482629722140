import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LINKS } from "utils";

import HeaderMenu from "./HeaderMenu";
import { Logo } from "components";
import { Burger } from "components/buttons";

import "./index.css";

const Header = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(
    document.body.scrollTop >= 100 || document.documentElement.scrollTop >= 100
  );
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled(
        document.body.scrollTop >= 100 ||
          document.documentElement.scrollTop >= 100
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const navigateToHomepage = () => {
    navigate(LINKS.home);

    setIsToggled(false);
  };

  return (
    <div
      className={`header ${isScrolled ? "scrolled" : ""} ${
        isToggled ? "active" : ""
      }`}
    >
      <div className="header__logo" onClick={navigateToHomepage} tabIndex="0">
        <Logo width={239.68} />
      </div>

      <Burger isToggled={isToggled} setIsToggled={setIsToggled} />

      <HeaderMenu setIsToggled={setIsToggled} />

      {/* <div className="header__cartWrap">
        <Cart />
    </div> */}
    </div>
  );
};

export default Header;
