import "./index.css";

const Button = ({ children, actionButtonStyle = "primary", ...rest }) => {
  return (
    <button
      className={`button ${
        actionButtonStyle === "primary" ? "primary" : "secondary"
      }`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
