import { useTranslation } from "react-i18next";

import { OvalImage, CircularImage, Container } from "components";

import "./index.css";

const OurStory = () => {
  const { t: translation } = useTranslation("layouts", {
    keyPrefix: "ourStory",
  });

  return (
    <div className="ourStory" id="ourStory">
      <div className="ourStory__image1 hide-under-desktop">
        <CircularImage
          image="/media/espresso-martini/espresso-martini-tall-3.jpg"
          size={250}
        />
      </div>

      <div className="ourStory__image2 hide-under-desktop">
        <OvalImage
          image="/media/family-shots/bottles-tall-2.png"
          style={{ width: 270, height: 414, borderRadius: 187 }}
        />
      </div>

      <Container>
        <h2 className="ourStory__title section-title section-title-divider">
          {translation("title")}
        </h2>

        <p className="ourStory__paragraph paragraph">
          {translation("paragraph")}
        </p>
      </Container>
    </div>
  );
};

export default OurStory;
