import { useTranslation } from "react-i18next";

import { Container } from "components";

import "./index.css";

const Copyright = () => {
  const { t: translation } = useTranslation("common", {
    keyPrefix: "copyright",
  });

  return (
    <div className="copyright">
      <Container>
        <p className="copyright__notice">{translation("notice")}</p>
      </Container>
    </div>
  );
};

export default Copyright;
