import "./index.css";

const HowToServe = ({ steps }) => {
  return (
    <div className="howToServe">
      <div className="howToServe__iconsWrap">
        {steps.map((element, index) => (
          <div className="howToServe__icon" key={index}>
            <img src={element.icon} alt={element.title} />
          </div>
        ))}
      </div>

      <div className="howToServe__circles">
        <div className="howToServe__circle">1</div>
        <div className="howToServe__circle">2</div>
        <div className="howToServe__circle">3</div>
        <div className="howToServe__circle">4</div>
      </div>

      <div className="howToServe__titlesWrap">
        {steps.map((element, index) => (
          <p className="howToServe__title" key={index}>
            {element.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default HowToServe;
