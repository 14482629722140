import { useNavigate } from "react-router-dom";

import { Button } from "components/buttons";

import "./index.css";

const ProductCard = ({ product, actionButtonsHidden = false }) => {
  let navigate = useNavigate();

  const navigateToPage = (productId) => {
    navigate(`/products/${productId}`);
  };

  const onOrderNowHandler = () => {
    window.ChatFoodWidget.orderNow();
  };

  return (
    <div className="productCard">
      <div
        className="productCard__imageWrap"
        onClick={navigateToPage.bind(this, product.id)}
      >
        <img
          className="productCard__image"
          src={product.imageUrl}
          alt={product.title}
          loading="lazy"
        />
      </div>

      <p className="productCard__title">{product.title}</p>

      {!actionButtonsHidden ? (
        <div className="productCard__actionButtons">
          <Button
            onClick={navigateToPage.bind(this, product.id)}
            actionButtonStyle="secondary"
          >
            KNOW YOUR QURE
          </Button>

          <Button onClick={onOrderNowHandler} actionButtonStyle="primary">
            Order Now
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ProductCard;
