import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import "./index.css";

const Slider = ({ slides = [] }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      grabCursor={true}
      slidesPerView={1}
      spaceBetween={30}
      modules={[Pagination, Autoplay]}
      loop={true}
      className="slider"
    >
      {slides.map((slide, index) => (
        <SwiperSlide className="slider__slide" key={index}>
          {slide.image && (
            <img
              className="slider__image"
              src={slide.image}
              alt={slide.imageAlt}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
