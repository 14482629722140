const Instagram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.178 20.122">
      <path
        id="Path_53"
        data-name="Path 53"
        d="M106.932,7.327a5.628,5.628,0,0,0-1.629-3.42,5.159,5.159,0,0,0-3.746-1.466,66.688,66.688,0,0,0-9.935.163,5.014,5.014,0,0,0-4.4,3.909c-.489,1.629-.326,9.609-.163,11.238a5.187,5.187,0,0,0,4.072,4.4c1.629.489,9.446.326,11.075,0a5.187,5.187,0,0,0,4.4-4.072C107.258,16.285,107.1,8.956,106.932,7.327Zm-1.954,10.1a3.635,3.635,0,0,1-3.257,3.094c-1.629.163-8.795.326-10.423-.163a3.1,3.1,0,0,1-2.443-2.769,69.564,69.564,0,0,1,0-10.1A3.635,3.635,0,0,1,92.112,4.4c1.792-.163,8.143-.163,9.935,0a3.635,3.635,0,0,1,3.094,3.257A65.955,65.955,0,0,1,104.978,17.425ZM97,7.164a5.212,5.212,0,0,0,0,10.423,5.145,5.145,0,0,0,5.212-5.212A5.145,5.145,0,0,0,97,7.164Zm0,8.469a3.34,3.34,0,1,1,3.42-3.257A3.41,3.41,0,0,1,97,15.633ZM103.512,7a1.14,1.14,0,1,1-1.14-1.14C103.023,5.7,103.512,6.35,103.512,7Z"
        transform="translate(-86.9 -2.334)"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default Instagram;
