import logo from "assets/media/logo.svg";

const Logo = ({ width }) => {
  return (
    <div className="logo">
      <img src={logo} alt="logo" width={width} />
    </div>
  );
};

export default Logo;
