import { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";

import { HELPERS, KEYS } from "utils";

import { Input } from "components/inputs";
import { Button } from "components/buttons";

import "./index.css";

const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
const FORM_VALIDATION = "FORM_VALIDATION";
const FORM_SUBMIT = "FORM_SUBMIT";
const FORM_RESET = "FORM_RESET";

const formInitialState = {
  inputs: {
    email: {
      inputIdentifier: "email",
      type: "email",
      isRequired: true,
      value: "",
    },
  },
  isFormValid: true,
  isFormSubmitted: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case FORM_INPUT_UPDATE:
      const updatedInputs = {
        ...state.inputs,
        [action.payload.inputIdentifier]: {
          ...state.inputs[action.payload.inputIdentifier],
          value: action.payload.inputValue,
        },
      };

      return {
        ...state,
        inputs: updatedInputs,
      };
    case FORM_VALIDATION:
      return {
        ...state,
        isFormValid: action.payload.isFomValid,
        isFormSubmitted: false,
      };
    case FORM_SUBMIT:
      return {
        ...state,
        isFormSubmitted: true,
      };
    case FORM_RESET:
      return {
        ...formInitialState,
      };

    default:
      return state;
  }
};

const FirstPurchaseForm = ({ formMessage, setFormMessage }) => {
  const [formState, dispatchFormState] = useReducer(
    formReducer,
    formInitialState
  );
  const [isLoading, setIsLoading] = useState(false);
  let mountedRef = useRef(true);

  const onFormInputChangeHandler = (inputIdentifier, inputValue) => {
    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      payload: {
        inputIdentifier: inputIdentifier,
        inputValue: inputValue,
      },
    });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setFormMessage("");

    let isFomValid = HELPERS.testFormValidation(formState.inputs);

    dispatchFormState({
      type: FORM_VALIDATION,
      payload: {
        isFormValid: isFomValid,
      },
    });

    if (!isFomValid) {
      return;
    }

    setIsLoading(true);

    let formData = new FormData();

    formData.append("service_id", KEYS.emailJsServiceId);
    formData.append("template_id", KEYS.emailJsFirstPurchaseFormTemplateId);
    formData.append("user_id", KEYS.emailJsPublicKey);
    formData.append("email", formState.inputs.email.value);

    try {
      await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send-form",
        formData
      );

      if (!mountedRef.current) return;

      localStorage.setItem(
        "firstPurchase",
        "Walid Hawari will not show you the modal again by using the localStorage web API"
      );

      setFormMessage("success");
    } catch (error) {
      if (!mountedRef.current) return;

      setFormMessage("fail");

      console.error("failed to send message", error);
    }

    if (!mountedRef.current) return;

    setIsLoading(false);

    dispatchFormState({ type: FORM_SUBMIT });
  };

  useEffect(() => {
    if (formState.isFormSubmitted) {
      dispatchFormState({ type: FORM_RESET });
    }
  }, [formState.isFormSubmitted]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <form className="firstPurchaseForm">
        <Input
          formInput={formState.inputs.email}
          onFormInputChangeHandler={onFormInputChangeHandler}
          isFormValid={formState.isFormValid}
          isFormSubmitted={formState.isFormSubmitted}
          placeholder="Email"
        />

        <Button onClick={onSubmitHandler} disabled={isLoading ? true : false}>
          Submit
        </Button>
      </form>

      {formMessage ? (
        <p
          className={`firstPurchaseForm__message ${
            formMessage === "success" ? "success" : "fail"
          }`}
        >
          {formMessage === "success"
            ? "Email submitted."
            : "Failed to submit email, please try again later."}
        </p>
      ) : null}
    </>
  );
};

export default FirstPurchaseForm;
