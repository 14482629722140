import { BrowserRouter as Router } from "react-router-dom";

import { LanguageProvider } from "contexts/LanguageContext";
import { ProductsProvider } from "contexts/ProductsContext";
import { CartProvider } from "contexts/CartContext";

import { Main } from "layouts";
import Routes from "routes";

const App = () => {
  return (
    <LanguageProvider>
      <ProductsProvider>
        <CartProvider>
          <Router>
            <Main>
              <Routes />
            </Main>
          </Router>
        </CartProvider>
      </ProductsProvider>
    </LanguageProvider>
  );
};

export default App;
