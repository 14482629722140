import { Spinner } from "components/icons";

import "./index.css";

const Loader = ({ isFullPageLoader = false }) => {
  return (
    <div className={`loader ${isFullPageLoader ? "loader__fullPage" : ""}`}>
      <Spinner />
    </div>
  );
};

export default Loader;
