import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import { HELPERS } from "utils";

import { Facebook, Instagram } from "components/icons";

import "./index.css";

const FooterMenu = () => {
  const { t: translation } = useTranslation("common", {
    keyPrefix: "footerMenu",
  });

  return (
    <nav className="footerMenu">
      <div className="footerMenu__column">
        <HashLink
          className="footerMenu__link"
          to="/#ourStory"
          smooth
          scroll={HELPERS.scrollIntoSection}
        >
          {translation("ourStory")}
        </HashLink>

        <HashLink
          className="footerMenu__link"
          to="/#mocktailsCollection"
          smooth
          scroll={HELPERS.scrollIntoSection}
        >
          {translation("products")}
        </HashLink>

        <HashLink
          className="footerMenu__link"
          to="/#meantToBeShared"
          smooth
          scroll={HELPERS.scrollIntoSection}
        >
          {translation("whyQure")}
        </HashLink>
      </div>

      <div className="footerMenu__column">
        <HashLink
          className="footerMenu__link"
          to="/#sendUsAMessage"
          smooth
          scroll={HELPERS.scrollIntoSection}
        >
          {translation("contactUs")}
        </HashLink>

        <p className="footerMenu__contact">
          <a href="mailto:info@quredrinks.com" target="_blank" rel="noreferrer">
            {translation("email")}
          </a>
        </p>

        <p className="footerMenu__contact">
          <a
            href="https://wa.me/+971585716777"
            target="_blank"
            rel="noreferrer"
          >
            {translation("phone")}
          </a>
        </p>
      </div>

      <div className="footerMenu__column">
        <p className="footerMenu__link">{translation("followUs")}</p>

        <a
          className="footerMenu__follow"
          href="https://www.instagram.com/quredrinks/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="footerMenu__socialIcon">
            <Instagram />
          </i>

          <span>{translation("instagram")}</span>
        </a>

        <a
          className="footerMenu__follow"
          href="https://www.facebook.com/Qure-Drinks-107450568392637"
          target="_blank"
          rel="noreferrer"
        >
          <i className="footerMenu__socialIcon">
            <Facebook />
          </i>

          <span>{translation("facebook")}</span>
        </a>
      </div>
    </nav>
  );
};

export default FooterMenu;
