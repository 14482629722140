class Product {
  constructor(
    id,
    title,
    description,
    price,
    quantity = 0,
    volumes = [],
    imageUrl,
    sliderImages = [],
    formats,
    keyIngredients,
    howToServe,
    order
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.price = price;
    this.quantity = quantity;
    this.volumes = volumes;
    this.imageUrl = imageUrl;
    this.sliderImages = sliderImages;
    this.formats = formats;
    this.keyIngredients = keyIngredients;
    this.howToServe = howToServe;
    this.order = order;
  }
}

export default Product;
