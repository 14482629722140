import "./index.css";

const CircularImage = ({ image, size }) => {
  return (
    <div className="circularImage" style={{ width: size, height: size }}>
      <img src={image} alt="circular" loading="lazy" />
    </div>
  );
};

export default CircularImage;
