import { useTranslation } from "react-i18next";

import { useProducts } from "contexts/ProductsContext";

import { Container } from "components";
import { ProductCard } from "layouts";

import "./index.css";

const MocktailsCollection = () => {
  const { t: translation } = useTranslation("layouts", {
    keyPrefix: "mocktailsCollection",
  });
  const { products } = useProducts();

  return (
    <div className="mocktailsCollection" id="mocktailsCollection">
      <Container>
        <h2 className="mocktailsCollection__title section-title section-title-divider">
          {translation("title")}
        </h2>

        <div className="mocktailsCollection__columns">
          {products
            .sort((a, b) => a.order - b.order)
            .map((element) => (
              <div className="mocktailsCollection__column" key={element.id}>
                <ProductCard product={element} />
              </div>
            ))}
        </div>
      </Container>
    </div>
  );
};

export default MocktailsCollection;
